<template>
  <div>
    <Header></Header>
    <div class="home-banner">
      <div class="all-title-ani">用先进技术帮助医疗机构</div>
      <div class="all-title-ani">提供高品质、可信赖的医疗务</div>
      <div class="all-title-ani all-ani-delay">进一步了解</div>
    </div>
    <div class="solution">
      <div class="powered-health">
        <div>
          赋能医疗机构全方位数智化转型<br />
          实现高质量可持续发展<br />
          开源、节流、增效<br />
          帮助医疗机构从优秀到卓越<br />
        </div>
      </div>
      <div style="background-color: #ffffff;">
        <div class="business-challenges">
          <img :src="`${$url}/solution/1.png`" alt="">
          <div>业务挑战</div>
          <div>
            <div>
              <div>为适应环境变化需要升级换代</div>
              <div>随着中国医改推进，取消药品、耗材加成,催使互联网智慧医疗服务不断完善升级。</div>
            </div>
            <div>
              <div>完善成熟的技术优势</div>
              <div>许多以前无法解决的难题被技术进步攻克了，技术水平逐渐趋于完善。</div>
            </div>
            <div>
              <div>为适应环境变化需要升级换代</div>
              <div>许多以前无法解决的难题被技术进步攻克了，技术水平逐渐趋于完善。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="improvement">
        <div class="All-title">解决痛点，实现系统的整体提升</div>
        <div class="program-box">
          <div>
            <div>
              <div>01</div>
              <div>规模发展</div>
              <div>助力医疗集团化规模化快速发展，改善工作环境变分散式维护管理为集中式维护管理。</div>
            </div>
          </div>
          <div>
            <div>
              <div>02</div>
              <div>医疗质控</div>
              <div>满足精细化医疗和医疗质控要求，基于系统对产品运行信息分析，为今后产品改进提供强大的数据。</div>
            </div>
          </div>
          <div>
            <div>
              <div>03</div>
              <div>降本增效</div>
              <div>提供医院降本增效技术服务保障，基于系统反馈的故障处理信息，不断的改进服务方式，提高服务量。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="solution-Technical">
        <div class="All-title">技术优势</div>
        <div class="All-box2">
          <div>
            <img :src="`${$url}/internet/8.png`" alt="">
            <div>更加轻量</div>
            <div>基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间，提升交付品质。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/9.png`" alt="">
            <div>更加强大</div>
            <div>真正开箱即用，全力推动客户价值的极速落地可视化的开发方式轻松打造物联网应用。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/10.png`" alt="">
            <div>更加简单</div>
            <div>在线应用使能平台，可视化的开发方式，轻松打造物联网应用。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/11.png`" alt="">
            <div>更加柔性</div>
            <div>在线热更新，避免停服升级造成的延误和损失，极速响应业务变更。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/12.png`" alt="">
            <div>更加纯粹</div>
            <div>更纯粹的开放平台，支持纵向扩展，以打造用户的自有物联网平台。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/13.png`" alt="">
            <div>更加智能</div>
            <div>使用云数智康通用网关接入各种现场设备，从容应对现场环境。</div>
          </div>
        </div>
      </div>
      <div class="solution-why">
        <div class="All-title">助力医疗机构高质量发展</div>
        <div class="why-body">
          <div>
            <div>
              <img :src="`${$url}/home/4.png`" alt="">
              <img :src="`${$url}/home/20.png`" alt="">
              <div>团队稳定靠谱</div>
              <div>团队成员来自消费医疗行业专家和互联网营销专家，为客户提供专业的解决方案。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/5.png`" alt="">
              <img :src="`${$url}/home/22.png`" alt="">
              <div>注重服务口碑</div>
              <div>提供专业的信息安全技术服务力量和24小时售后服务团队，整体提高服务效能。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/6.png`" alt="">
              <img :src="`${$url}/home/23.png`" alt="">
              <div>产品创新迭代</div>
              <div>践行国家战略提倡推行的医疗信息化及“互联网+医疗”模式，助力医疗行业发展。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/7.png`" alt="">
              <img :src="`${$url}/home/21.png`" alt="">
              <div>安稳可信赖</div>
              <div>以云数智康数据中心资源为基础，提供一站式服务解决方案，多方面保障安全性。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-assurance">
        <div class="All-title">产品保障</div>
        <div class="swiper-box">
          <div class="swiper-wrapper">
            <div class="swiper-slide my-swiper-slide">
              <div class="solution-assurance">
                <img :src="`${$url}/solution/2.png`" alt="">
                <div>精准定制</div>
                <div>
                  <div>医疗治控</div>
                  <div>数据治理</div>
                </div>
                <div>建立术前沟通机制，根据发友年龄性别、五官比例及需求量身定制方案。</div>
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="solution-assurance">
                <img :src="`${$url}/solution/3.png`" alt="">
                <div>全天侯面</div>
                <div>
                  <div>生态互联</div>
                  <div>多元经营</div>
                </div>
                <div>7*24小时，全年365天，全天侯面向发友提供专业毛发健康咨询与服务。</div>
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="solution-assurance">
                <img :src="`${$url}/solution/2.png`" alt="">
                <div>合同保障</div>
                <div>
                  <div>整合营销</div>
                  <div>个性服务</div>
                </div>
                <div>术前签订八大服务承诺协议，从法律效益上，明确了发友在术前术中和术后的权益保障。</div>
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="solution-assurance">
                <img :src="`${$url}/solution/2.png`" alt="">
                <div>保险保障</div>
                <div>
                  <div>医疗治控</div>
                  <div>数据治理</div>
                </div>
                <div>同中国平安保险达成战略合作，为医护人员提供百万医疗责任险，让发友放心，安心变美。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-show">
          <div v-for="item in 4" :key="item" :class="{'select-show':isMilepost ==item}">
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import '../style/swiper.css'
export default {
  components: {
    Header,
    Footer
  },
  methods: {
    initSwiper() {
      const that = this
      this.mySwiper = new Swiper('.swiper-box',{
        direction: 'horizontal',
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000 // 1秒切换一次
        },
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            if (this.activeIndex == 5) {
              that.isMilepost = 1
            } else {
              that.isMilepost = this.activeIndex
            }
          }
        }
      })
    }
  },
  mounted() {
    this.initSwiper()
  },
  data() {
    return {
      mySwiper: null,
      isMilepost: 1
    }
  }
}
</script>

<style lang="scss">
.solution {
  .business-challenges {
    width: 702px;
    padding-top: 48px;
    margin: 0 auto;
    padding-bottom: 64px;

    img {
      width: 100%;
    }
    > div:nth-child(2) {
      font-size: 34px;
      font-weight: 500;
      color: #181818;
      margin-top: 32px;
    }
    > div:nth-child(3) {
      > div {
        margin-top: 32px;
        > div:nth-child(1) {
          position: relative;
          padding-left: 48px;
          font-size: 30px;
          font-weight: 500;
          color: #181818;
          margin-bottom: 12px;
          &::after {
            width: 32px;
            height: 32px;
            background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/medical/9.png')
              no-repeat;
            background-size: 100% 100%;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        > div:nth-child(2) {
          padding-left: 48px;
          font-size: 26px;
          font-weight: 400;
          color: #7b7b7b;
          line-height: 40px;
        }
      }
    }
  }
  .improvement {
    padding-top: 100px;
    background: #f5f6f8;
    padding-bottom: 68px;
    .program-box {
      > div {
        &:hover {
          &::after {
            opacity: 1;
            background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/solution/6.png')
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .solution-Technical {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/14.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 90px;
    padding-top: 100px;
  }
  .solution-why {
    width: 100%;
    margin-top: -1px;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/3.png')
      no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-bottom: 90px;
    padding-top: 100px;
    .why-body {
      margin-top: 64px;
    }
  }
  .product-assurance {
    background: #f5f6f8;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
    .All-title {
      margin-bottom: 64px;
    }
    .solution-assurance {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 640px;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
      > div:nth-child(2) {
        font-size: 40px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 120px;
        margin-bottom: 64px;
      }
      > div:nth-child(3) {
        display: flex;
        justify-content: center;
        > div {
          width: 188px;
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          line-height: 78px;
          text-align: center;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 188px;
            height: 78px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            opacity: 0.3;
          }
        }
        > div:nth-child(1) {
          margin-right: 32px;
        }
      }
      > div:nth-child(4) {
        width: 622px;
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        margin: 45px auto 0;
        text-align: center;
      }
    }
  }
}
</style>
